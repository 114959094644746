// src/components/Login.js
import React, { useState } from 'react';
import styles from '../assets/css/stylelogin.module.css';

const Login = ({ addNotification, onLoginSuccess }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://olimp.uor-ekb.ru/new_admin/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login, password }),
      });

      if (!response.ok) {
        throw new Error('Сервер вернул ошибку: ' + response.statusText);
      }

      const data = await response.json();

      if (data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Авторизация прошла успешно',
          actions: ['OK'],
        });
        console.log(data.user);
        localStorage.setItem('user', JSON.stringify(data.user));
        onLoginSuccess(data.user); // Перенесено после сохранения в localStorage
      } else {
        setErrorMessage(data.message || 'Ошибка авторизации.');
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: data.message || 'Ошибка авторизации.',
          actions: ['OK'],
        });
      }
    } catch (error) {
      setErrorMessage('Не удалось подключиться к серверу. Попробуйте позже.');
      addNotification({
        icon: 'error',
        title: 'Ошибка подключения',
        subtitle: 'Не удалось подключиться к серверу. Попробуйте позже.',
        actions: ['OK'],
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles['form-container']} ${styles['sign-in']}`}>
        <form onSubmit={handleLogin}>
          <h1>Авторизация</h1>
          <span>Введите ваши данные</span>
          <input
            type="text"
            placeholder="Логин"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input type="submit" value="Войти" />
          <button type="button" className={styles['forgot-password']}>
            Забыли пароль?
          </button>
        </form>
      </div>
      <div className={styles['toggle-container']}>
        <div className={styles.toggle}>
          <div className={`${styles['toggle-panel']} ${styles['toggle-right']}`}>
            <h1>Добро пожаловать!</h1>
            <p>Авторизуйтесь для доступа к админ панели Олимпийца!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
