import React, { useState } from 'react';
import '../../assets/css/Settings.css'; // Стили

const tabs = [
  { key: 'export', label: 'Экспорт' },
  { key: 'groups', label: 'Группы' },
  { key: 'roles', label: 'Роли' },
  { key: 'sports', label: 'Виды спорта' },
  { key: 'app', label: 'Управление приложением' },
  { key: 'update', label: 'Обновление' },
];

const Settings = ({ addNotification }) => {
  const [activeTab, setActiveTab] = useState('export');
  const [fromId, setFromId] = useState('');
  const [toId, setToId] = useState('');

  const handleDownload = (url) => {
    // Простейший метод скачивания
    window.location.href = url;
  };

  const handleRangeExport = () => {
    if (!fromId || !toId) {
      addNotification && addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Укажите диапазон ID',
        actions: ['OK']
      });
      return;
    }
    const url = `https://olimp.uor-ekb.ru/new_admin/export_users.php?type=range&from=${encodeURIComponent(fromId)}&to=${encodeURIComponent(toId)}`;
    handleDownload(url);
  };

  return (
    <div className="settings-container">


      <div className="settings-tabs">
        {tabs.map(tab => (
          <button
            key={tab.key}
            className={`settings-tab-button ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="settings-tab-content">
        {activeTab === 'export' && (
          <div className="export-section">
            <h3>Экспорт пользователей</h3>
            <div className="export-buttons">
              <button onClick={() => handleDownload('https://olimp.uor-ekb.ru/new_admin/export_users.php?type=bsp')}>
                Экспорт БСП
              </button>
              <button onClick={() => handleDownload('https://olimp.uor-ekb.ru/new_admin/export_users.php?type=no_bsp')}>
                Экспорт без БСП
              </button>
              <button onClick={() => handleDownload('https://olimp.uor-ekb.ru/new_admin/export_users.php?type=all')}>
                Экспорт всех
              </button>
              <button onClick={() => handleDownload('https://olimp.uor-ekb.ru/new_admin/export_users.php?type=personnel')}>
                Экспорт персонала
              </button>
            </div>
            <div className="export-range">
              <h4>Экспорт по диапазону ID</h4>
              <div className="range-inputs">
                <input 
                  type="number" 
                  placeholder="От ID" 
                  value={fromId} 
                  onChange={(e) => setFromId(e.target.value)} 
                />
                <input 
                  type="number" 
                  placeholder="До ID" 
                  value={toId} 
                  onChange={(e) => setToId(e.target.value)} 
                />
                <button onClick={handleRangeExport}>Экспорт диапазона</button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'groups' && <div>Функционал для групп (Заглушка)</div>}
        {activeTab === 'roles' && <div>Функционал для ролей (Заглушка)</div>}
        {activeTab === 'sports' && <div>Функционал для видов спорта (Заглушка)</div>}
        {activeTab === 'app' && <div>Управление приложением (Заглушка)</div>}
        {activeTab === 'update' && <div>Обновление (Заглушка)</div>}
      </div>
    </div>
  );
};

export default Settings;
