// src/components/pages/Tasks.js
import React, { useState, useEffect } from 'react';
import '../../assets/css/tasks.css';
import { FaCheck, FaDownload, FaEdit, FaWindowClose } from 'react-icons/fa';
import axios from 'axios';

const Tasks = ({ user, addNotification }) => {
  const debug = true;

  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('Все');
  const [loading, setLoading] = useState(true);
  const [editingApplication, setEditingApplication] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  const apiEndpoint = 'https://olimp.uor-ekb.ru/ubitie_api.php';

  const roleNamesToIds = {
    'user': 1,
    'admin': 2,
    'passport': 4,
    'manager': 5,
    'educator': 19,
    'coach': 20,
    'canteen': 23,
    'doctor': 24,
    'methodist': 25,
    'education': 26,
  };

  const roleIdsToNames = Object.fromEntries(
    Object.entries(roleNamesToIds).map(([name, id]) => [id, name])
  );

  const reasonToRoleId = {
    'По болезни': roleNamesToIds['doctor'],
    'Сборы/Соревнования': roleNamesToIds['methodist'],
    'По семейным обстоятельствам': roleNamesToIds['education'],
    'На каникулы': roleNamesToIds['education'],
  };

  const statusColors = {
    'Ожидание подтверждения врача': 'red',
    'Ожидание подтверждения методиста': 'red',
    'Ожидание подтверждения учебного отдела': 'red',
    'Подтвержден': 'yellow',
    'Одобрено': 'green',
    'Отклонено': 'gray',
  };

  const userRoleId = parseInt(user?.role, 10) || 0;
  const userRoleName = roleIdsToNames[userRoleId] || '';

  if (debug) {
    console.log('User object:', user);
    console.log('User Role ID:', userRoleId);
    console.log('User Role Name:', userRoleName);
  }

  useEffect(() => {
    if (debug) console.log('Fetching applications...');
    fetchApplications();
  }, []);

  useEffect(() => {
    if (debug) {
      console.log('Filtering applications...');
      console.log('Current user role:', userRoleName);
      console.log('Applications:', applications);
      console.log('Search term:', searchTerm);
      console.log('Status filter:', statusFilter);
    }
    filterApplications();
  }, [searchTerm, statusFilter, applications]);

  const fetchApplications = async () => {
    try {
      const response = await axios.post(apiEndpoint, {
        action: 'adminList',
        userId: user.id,
      });
      if (debug) {
        console.log('API response:', response.data);
      }
      if (response.data.success) {
        setApplications(response.data.data);
        setLoading(false);
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при загрузке данных',
          actions: ['OK'],
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
      setLoading(false);
    }
  };

  const filterApplications = () => {
    let filtered = applications;

    if (searchTerm) {
      filtered = filtered.filter((app) =>
        app.userFIO.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter !== 'Все') {
      filtered = filtered.filter((app) => getStatusDisplayName(app.status) === statusFilter);
    }

    setFilteredApplications(filtered);
    setCurrentPage(0);

    if (debug) {
      console.log('Filtered applications:', filtered);
    }
  };

  const handleApprove = async (applicationId) => {
    if (['admin', 'educator'].includes(userRoleName)) {
        if (!window.confirm('Вы действительно хотите одобрить заявку без предварительного подтверждения?')) {
          return;
        }
      }
    if (debug) {
      console.log(`Approving application ID ${applicationId} by userId ${user.id}`);
    }
    try {
      const response = await axios.post(apiEndpoint, {
        action: 'adminApprove',
        applicationId,
        userId: user.id,
      });
      if (debug) {
        console.log('Approve response:', response.data);
      }
      if (response.data.success) {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: response.data.message || 'Заявка одобрена',
          actions: ['OK'],
        });
        fetchApplications();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при одобрении заявки',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const handleReject = async (applicationId) => {
    if (debug) {
      console.log(`Rejecting application ID ${applicationId} by userId ${user.id}`);
    }
    try {
      const response = await axios.post(apiEndpoint, {
        action: 'adminReject',
        applicationId,
        userId: user.id,
      });
      if (debug) {
        console.log('Reject response:', response.data);
      }
      if (response.data.success) {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: response.data.message || 'Заявка отклонена',
          actions: ['OK'],
        });
        fetchApplications();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при отклонении заявки',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const handleDownload = async (applicationId) => {
    if (debug) {
      console.log(`Downloading file for application ID ${applicationId}`);
    }
    try {
      const response = await axios.post(apiEndpoint, {
        action: 'generateFile',
        applicationId,
        userId: user.id,
      });
      if (debug) {
        console.log('Generate file response:', response.data);
      }
      if (response.data.success && response.data.filePath) {
        const fileUrl = `https://olimp.uor-ekb.ru/${response.data.filePath}`;
        window.open(fileUrl, '_blank');
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при генерации файла',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при генерации файла:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при генерации файла',
        actions: ['OK'],
      });
    }
  };

  const handleEdit = (application) => {
    if (debug) {
      console.log('Editing application:', application);
    }
    setEditingApplication(application);
  };

  const handleSaveEdit = async () => {
    if (debug) {
      console.log('Saving edited application:', editingApplication);
    }
    try {
      const response = await axios.post(apiEndpoint, {
        action: 'adminUpdateDates',
        applicationId: editingApplication.id,
        departureFromDate: editingApplication.departureFromDate,
        departureToDate: editingApplication.departureToDate,
        userId: user.id,
      });
      if (debug) {
        console.log('Update dates response:', response.data);
      }
      if (response.data.success) {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Даты обновлены',
          actions: ['OK'],
        });
        setEditingApplication(null);
        fetchApplications();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при обновлении дат',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при обновлении дат:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при обновлении дат',
        actions: ['OK'],
      });
    }
  };

  const totalPages = Math.ceil(filteredApplications.length / itemsPerPage);

  const displayedApplications = filteredApplications.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === 'next' && prevPage < totalPages - 1) {
        return prevPage + 1;
      }
      if (direction === 'prev' && prevPage > 0) {
        return prevPage - 1;
      }
      return prevPage;
    });
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const allStatuses = [
    'Все',
    'Ожидание подтверждения врача',
    'Ожидание подтверждения методиста',
    'Ожидание подтверждения учебного отдела',
    'Подтвержден',
    'Одобрено',
    'Отклонено',
  ];

  const limitedStatuses = ['Все', 'Новая', 'В обработке', 'Завершена'];

  const isSpecialRole = [roleNamesToIds['admin'], roleNamesToIds['educator'], roleNamesToIds['canteen']].includes(userRoleId);

  const statusOptions = isSpecialRole ? allStatuses : limitedStatuses;

  const getStatusDisplayName = (status) => {
    if (isSpecialRole) {
      return status;
    }
    switch (status) {
      case 'Ожидание подтверждения врача':
      case 'Ожидание подтверждения методиста':
      case 'Ожидание подтверждения учебного отдела':
        return 'Новая';
      case 'Подтвержден':
        return 'В обработке';
      case 'Одобрено':
      case 'Отклонено':
        return 'Завершена';
      default:
        return status;
    }
  };

  return (
    <div className="tasks">
      <div className="tasks-header">
        <input
          type="text"
          placeholder="Поиск по ФИО"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="status-filters">
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {getStatusDisplayName(status)}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <p className="pcolor">Загрузка...</p>
      ) : (
        <>
          <div className="application-list">
            {displayedApplications.map((app) => (
              <div className="application-card" key={app.id}>
                <div
                  className={`status-circle ${
                    statusColors[app.status] || 'gray'
                  }`}
                ></div>
                <div className="application-info">
                  <img src={app.Avatar} alt="Avatar" className="avatar" />
                  <div>
                  <h3>{app.userFIO}</h3>
                  <p>{`Причина: ${app.reason}`}</p>
                  <p>{`С ${formatDate(
                    app.departureFromDate
                  )} по ${formatDate(app.departureToDate)}`}</p>
                  <p>{`Статус: ${getStatusDisplayName(app.status)}`}</p>
                  </div>
                </div>
                <div className="application-actions">
                  {(app.status.startsWith('Ожидание подтверждения') &&
                    (reasonToRoleId[app.reason] === userRoleId || userRoleName === 'admin' || userRoleName === 'educator')) && (
                    <>
                      <button
                        className="action-button"
                        onClick={() => handleEdit(app)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="action-button"
                        onClick={() => handleApprove(app.id)}
                      >
                        <FaCheck />
                      </button>
                      <button
                        className="action-button"
                        onClick={() => handleReject(app.id)}
                      >
                        <FaWindowClose />
                      </button>
                    </>
                  )}
                  {app.status === 'Подтвержден' &&
                    [roleNamesToIds['educator'], roleNamesToIds['admin']].includes(userRoleId) && (
                      <>
                        <button
                          className="action-button"
                          onClick={() => handleEdit(app)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="action-button"
                          onClick={() => handleDownload(app.id)}
                        >
                          <FaDownload />
                        </button>
                        <button
                          className="action-button"
                          onClick={() => handleApprove(app.id)}
                        >
                          <FaCheck />
                        </button>
                        <button
                          className="action-button"
                          onClick={() => handleReject(app.id)}
                        >
                          <FaWindowClose />
                        </button>
                      </>
                    )}
                  {app.status === 'Одобрено' &&
                    [roleNamesToIds['educator'], roleNamesToIds['admin'], roleNamesToIds['canteen']].includes(userRoleId) && (
                      <button
                        className="action-button"
                        onClick={() => handleDownload(app.id)}
                      >
                        <FaDownload />
                      </button>
                    )}
                </div>
              </div>
            ))}
          </div>

          {editingApplication && (
            <div className="tasks-modal-overlay">
              <div className="tasks-modal">
                <h2>Редактирование дат</h2>
                <label>
                  Дата с:
                  <input
                    type="date"
                    value={editingApplication.departureFromDate}
                    onChange={(e) =>
                      setEditingApplication({
                        ...editingApplication,
                        departureFromDate: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Дата по:
                  <input
                    type="date"
                    value={editingApplication.departureToDate}
                    onChange={(e) =>
                      setEditingApplication({
                        ...editingApplication,
                        departureToDate: e.target.value,
                      })
                    }
                  />
                </label>
                <div className="tasks-modal-actions">
                  <button onClick={handleSaveEdit}>Сохранить</button>
                  <button onClick={() => setEditingApplication(null)}>
                    Отмена
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="pagination-container">
            <div className="pagination-left">
              <span>Всего: {filteredApplications.length}</span>
            </div>
            <div className="pagination-center">
              <button
                onClick={() => handlePageChange('prev')}
                disabled={currentPage === 0}
              >
                &lt;
              </button>
              <span>
                {currentPage + 1} / {totalPages}
              </span>
              <button
                onClick={() => handlePageChange('next')}
                disabled={currentPage >= totalPages - 1}
              >
                &gt;
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Tasks;
