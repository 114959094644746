// src/components/BulkAddUsersModal.js
import React, { useState } from 'react';
import '../assets/css/bulkAddModal.css';
import { MdEdit, MdDelete, MdInfoOutline } from "react-icons/md";

const BulkAddUsersModal = ({ closeModal, addNotification, onBack }) => {
  const [rawText, setRawText] = useState("");
  const [users, setUsers] = useState([]);
  const [isParsed, setIsParsed] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedUser, setEditedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const generatePassword = (length = 8) => {
    const chars = 'abcdefghjkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      password += chars[Math.floor(Math.random() * chars.length)];
    }
    return password;
  };

  const transliterate = (text) => {
    const map = {
      'а':'a','б':'b','в':'v','г':'g','д':'d','е':'e','ё':'e','ж':'zh','з':'z','и':'i','й':'i',
      'к':'k','л':'l','м':'m','н':'n','о':'o','п':'p','р':'r','с':'s','т':'t','у':'u','ф':'f',
      'х':'kh','ц':'ts','ч':'ch','ш':'sh','щ':'shch','ы':'y','э':'e','ю':'yu','я':'ya',
      'ь':'','ъ':'','ґ':'g','є':'ye','і':'i','ї':'yi'
    };
    let result = '';
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      const lowerChar = char.toLowerCase();
      const latinChar = map[lowerChar] ?? lowerChar;
      result += (char === char.toUpperCase()) ? latinChar.toUpperCase() : latinChar;
    }
    return result;
  };

  const parseUsers = () => {
    const lines = rawText.trim().split('\n');
    const parsed = [];
  
    // Начинаем с 0, чтобы не пропускать первую строку.
    for (let i = 0; i < lines.length; i++) {
      const cols = lines[i].split('\t').map(c => c.trim());
      if (cols.length < 2) continue;
  
      const fio = cols[1] || "";
      if (!fio) continue;
  
      const fioParts = fio.split(/\s+/);
      const surname = fioParts[0] || "";
      const name = fioParts[1] || "";
      const loginBase = transliterate(surname) + (name ? transliterate(name[0]) : '');
      const login = (cols[3] && cols[3] !== "") ? cols[3] : loginBase.toLowerCase();
      const password = (cols[4] && cols[4] !== "") ? cols[4] : generatePassword(8);
      const telephone = (cols[2] && cols[2] !== "") ? cols[2] : "";
  
      parsed.push({
        fio,
        telephone,
        login,
        password,
      });
    }
  
    checkUsers(parsed, true);
  };
  

  const checkUsers = async (parsedUsers, checkOnly = false) => {
    setIsLoading(true);
    try {
      const response = await fetch('https://olimp.uor-ekb.ru/new_admin/bulk_add_users.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ users: parsedUsers, checkOnly })
      });
      const result = await response.json();
      if (result.status === 'success') {
        setUsers(result.data);
        setIsParsed(true);
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: result.message || 'Ошибка при проверке пользователей',
          actions: ['OK']
        });
      }
    } catch (error) {
      addNotification({
        icon: 'error',
        title: 'Ошибка сети',
        subtitle: 'Не удалось связаться с сервером при проверке пользователей',
        actions: ['OK']
      });
    }
    setIsLoading(false);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedUser({ ...users[index] });
  };

  const handleDelete = (index) => {
    const updated = [...users];
    updated.splice(index, 1);
    setUsers(updated);
  };

  const handleSaveEdit = () => {
    const updated = [...users];
    updated[editingIndex] = editedUser;
    setUsers(updated);
    setEditingIndex(null);
    setEditedUser(null);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedUser(null);
  };

  const handleAddAll = async () => {
    // Добавляем только тех, кто не существовал
    const filteredUsers = users.filter(u => !u.existingUser);
    if (filteredUsers.length === 0) {
      addNotification({
        icon: 'info',
        title: 'Информация',
        subtitle: 'Все пользователи уже существуют, добавлять некого.',
        actions: ['OK']
      });
      return;
    }

    setIsLoading(true);
    try {
      // Теперь отправляем без checkOnly, чтобы реально добавить
      const response = await fetch('https://olimp.uor-ekb.ru/new_admin/bulk_add_users.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ users: filteredUsers, checkOnly: false })
      });
      const result = await response.json();
      if (result.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Все новые пользователи добавлены успешно!',
          actions: ['OK']
        });
        closeModal();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: result.message || 'Ошибка при добавлении пользователей',
          actions: ['OK']
        });
      }
    } catch (error) {
      addNotification({
        icon: 'error',
        title: 'Ошибка сети',
        subtitle: 'Не удалось связаться с сервером при добавлении пользователей',
        actions: ['OK']
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="bulk-modal-overlay">
      <div className="bulk-modal-content">
        {onBack && (
          <button className="bulk-back-button" onClick={onBack}>←</button>
        )}
        <h2>Массовое добавление пользователей</h2>
        {!isParsed && !isLoading && (
          <>
            <p style={{fontSize: '14px'}}>Вставьте данные о пользователях (формат: №, ФИО, телефон...)</p>
            <textarea 
              value={rawText} 
              onChange={(e) => setRawText(e.target.value)} 
              rows={10}
              style={{width: '100%', boxSizing: 'border-box', fontSize: '14px', padding: '10px'}}
            />
            <div className="bulk-modal-actions">
              <button onClick={parseUsers} disabled={!rawText.trim()}>Обработать</button>
              <button onClick={closeModal}>Отмена</button>
            </div>
          </>
        )}

        {isLoading && (
          <p>Загрузка и проверка пользователей...</p>
        )}

        {isParsed && !isLoading && (
          <>
            <h3 style={{fontSize: '16px', marginBottom: '10px'}}>Проверка и корректировка списка</h3>
            <div className="bulk-users-container">
              {users.map((user, index) => {
                const isExisting = user.existingUser === true;
                return (
                  <div 
                    className="bulk-user-block" 
                    key={index} 
                    style={isExisting ? {borderColor: 'red'} : {}}
                  >
                    {editingIndex === index ? (
                      <>
                        <div className="bulk-user-field">
                          <label>ФИО:</label>
                          <input 
                            type="text" 
                            value={editedUser.fio} 
                            onChange={e => setEditedUser({...editedUser, fio: e.target.value})}
                          />
                        </div>
                        <div className="bulk-user-field">
                          <label>Телефон:</label>
                          <input 
                            type="text"
                            value={editedUser.telephone}
                            onChange={e => setEditedUser({...editedUser, telephone: e.target.value})}
                          />
                        </div>
                        <div className="bulk-user-field">
                          <label>Логин:</label>
                          <input 
                            type="text"
                            value={editedUser.login}
                            onChange={e => setEditedUser({...editedUser, login: e.target.value})}
                          />
                        </div>
                        <div className="bulk-user-field">
                          <label>Пароль:</label>
                          <input 
                            type="text"
                            value={editedUser.password}
                            onChange={e => setEditedUser({...editedUser, password: e.target.value})}
                          />
                        </div>
                        <div className="bulk-modal-actions">
                          <button onClick={handleSaveEdit}>Сохранить</button>
                          <button onClick={handleCancelEdit}>Отмена</button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <p style={{margin:0}}>
                            <strong>ФИО:</strong> {user.fio}
                          </p>
                          {isExisting && (
                            <MdInfoOutline 
                              style={{color: 'red', cursor: 'default'}} 
                              title="Пользователь уже существует. Повторно добавлен не будет."
                            />
                          )}
                        </div>
                        <p><strong>Телефон:</strong> {user.telephone}</p>
                        <p><strong>Логин:</strong> {user.login}</p>
                        <p><strong>Пароль:</strong> {user.password}</p>
                        {isExisting && (
                          <p style={{color:'red', fontSize:'12px'}}>
                            Этот пользователь уже есть в системе и не будет добавлен повторно.
                          </p>
                        )}
                        <div className="bulk-modal-actions" style={{justifyContent: 'space-between'}}>
                          <button onClick={() => handleEdit(index)} style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <MdEdit /> Ред.
                          </button>
                          <button onClick={() => handleDelete(index)} style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <MdDelete /> Удал.
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="bulk-modal-actions" style={{justifyContent: 'flex-end'}}>
              {users.length > 0 && <button onClick={handleAddAll}>Добавить всех</button>}
              <button onClick={closeModal}>Закрыть</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BulkAddUsersModal;
