import { FaHome, FaUser, FaCog, FaTasks, FaRegNewspaper, FaFile } from 'react-icons/fa';
import Dashboard from './pages/Dashboard';
import Tasks from './pages/Tasks';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import NewsManagement from './pages/NewsManagement';
import Propiski from './pages/Propiski';

const RoleBasedMenu = (roleId) => {
  // Словарь ролей: ID роли => Имя роли
  const roles = {
    1: 'user',         // Пользователь
    2: 'admin',        // Администратор
    4: 'passport',     // Паспортист
    5: 'manager',      // Менеджер
    19: 'educator',    // Воспитатель
    20: 'coach',       // Тренер
    23: 'canteen',     // Столовая
    24: 'doctor',      // Врач
    25: 'methodist',   // Методист
    26: 'education',   // Учебный отдел
  };

  // Все элементы меню с доступными ролями
  const allMenuItems = [
    {
      path: '/dashboard',
      title: 'Главная',
      icon: FaHome,
      component: Dashboard,
      roles: ['admin'],
    },
    {
      path: '/profile',
      title: 'Профиль',
      icon: FaUser,
      component: Profile,
      roles: ['admin'],
    },
    {
      path: '/news-management',
      title: 'Новости',
      icon: FaRegNewspaper,
      component: NewsManagement,
      roles: ['admin', 'manager'], // Доступ только для администраторов и менеджеров
    },
    {
      path: '/propiski',
      title: 'Прописки',
      icon: FaFile,
      component: Propiski,
      roles: ['admin', 'passport'], // Доступ только для администраторов и паспортистов
    },
    {
      path: '/tasks',
      title: 'Задачи',
      icon: FaTasks,
      component: Tasks,
      roles: [
        'admin',
        'passport',
        'educator',
        'coach',
        'canteen',
        'doctor',
        'methodist',
        'education',
        'manager',
      ], // Доступ для задач — расширенный список ролей
    },
    {
      path: '/settings',
      title: 'Настройки',
      icon: FaCog,
      component: Settings,
      roles: ['admin'], // Доступ только для администраторов
    },
  ];

  // Определяем имя роли по ее ID
  const roleName = roles[roleId];

  console.log('Role ID:', roleId); // Логируем роль ID для отладки
  console.log('Role Name:', roleName); // Логируем имя роли для отладки

  // Возвращаем элементы меню, доступные для роли
  return roleName
    ? allMenuItems.filter((item) => item.roles.includes(roleName))
    : []; // Если роль не определена, возвращаем пустой массив
};

export default RoleBasedMenu;
